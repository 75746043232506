<template>
  <div class="studentIndex">
    <audio id="audio" ref="audio" src="../../../public/sound/clickSound.wav" type="audio/wav" style="width: 0;height: 0;position: absolute;top: 0;"></audio>
    <audio id="audioNoclick" ref="audioNoclick" src="../../../public/sound/noclick.mp3" type="audio/mp3" style="width: 0;height: 0;position: absolute;top: 0;"></audio>
    <router-view @clickAudio="clickAudio" @clickNoAudio="clickNoAudio"></router-view>
    <div class="mask_box" ref="maskBox"></div>
  </div>
</template>

<script>
import { myPersonalConfig } from '@/api/student'
export default {
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    clickAudio() {
      this.$refs.audio.play()
    },
    clickNoAudio() {
      this.$refs.audioNoclick.play()
    },
  },
  mounted() {
    // 个人资料里面护眼模式打开，sessionStorage里面eye是字符串true，关闭是字符串false
    myPersonalConfig({ xsid: this.$store.state.userInfo.yhid }).then(res => {
      if (!res.result) {
        return
      }
      this.$refs.maskBox.style = res.result.hyms ? 'background:rgba(237, 251, 239, .3);' : ''
    })
    this.$Bus.$on('eye', val => {
      console.log(val)
      this.$refs.maskBox.style = sessionStorage.getItem('eye') == 'true' ? 'background:rgba(237, 251, 239, .3);' : ''
    })
    this.$Bus.$on('lock', msg => {
      if (msg.indexOf('on')) {
        // this.$lock('on')
        // 判断是否是教师班级下的学生
        if (msg.includes(JSON.parse(sessionStorage.getItem('userInfo')).yhid)) {
          this.$lock('on')
          this.$Bus.$emit('whether', true) // 传给测试页 清除定时器
        }
      } else if (msg.indexOf('off')) {
        // this.$lock('off')
        if (msg.includes(JSON.parse(sessionStorage.getItem('userInfo')).yhid)) {
          this.$lock('off')
          this.$Bus.$emit('whether', false)
        }
      }
    })
    // 禁止复制粘贴
    this.$nextTick(() => {
      if (document.body.offsetWidth < 1300) {
        document.onselectstart = new Function("event.returnValue=false");
      }
    })
    // 禁止浏览器前进后退
    window.history.forward(-1)
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function () {
      window.history.forward(-1)
      history.pushState(null, null, document.URL);
    })
  },
}
</script>
<style lang="scss" scoped>
.mask_box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: rgba(237, 251, 239, .3);
  pointer-events: none;
  z-index: 5002;
}
</style>
<style lang="scss">
.el-message--success {
  background-color: rgba(185, 208, 255, 1) !important;
  border-color: #fff !important;
  box-shadow: 0 2px 9px 2px rgba(22, 54, 118, 0.2) !important;
  opacity: 0.9 !important;
  height: 58px;
  z-index: 3001;
}
.el-message--error {
  background-color: rgba(185, 208, 255, 1) !important;
  border-color: #fff !important;
  box-shadow: 0 2px 9px 2px rgba(22, 54, 118, 0.2) !important;
  opacity: 0.9 !important;
  height: 58px;
  z-index: 3001;
}
.el-message--warning {
  background-color: rgba(185, 208, 255, 1) !important;
  border-color: #fff !important;
  box-shadow: 0 2px 9px 2px rgba(22, 54, 118, 0.2) !important;
  opacity: 0.9 !important;
  height: 58px;
  z-index: 3001;
}
.el-message--success .el-message__content {
  font-size: 24px !important;
  color: #333 !important;
  padding-left: 20px;
}
.el-message--error .el-message__content {
  font-size: 24px !important;
  color: #333 !important;
  padding-left: 20px;
}
.el-message--warning .el-message__content {
  font-size: 24px !important;
  color: #333 !important;
  padding-left: 20px;
}
.el-message .el-icon-success {
  color: #67c23a;
  width: 30px;
  height: 30px;
  display: inline-block;
  background: red;
  background: url("../../assets/images/student/yes.png") no-repeat;
  background-size: 100% 100%;
}
.el-message .el-icon-error {
  color: #67c23a;
  width: 30px;
  height: 30px;
  display: inline-block;
  background: red;
  background: url("../../assets/images/student/no.png") no-repeat;
  background-size: 100% 100%;
}
.el-message .el-icon-warning {
  color: #67c23a;
  width: 30px;
  height: 30px;
  display: inline-block;
  background: red;
  background: url("../../assets/images/student/sigh.png") no-repeat;
  background-size: 100% 100%;
}
.el-icon-success:before {
  display: none;
}
.el-icon-error:before {
  display: none;
}
.el-icon-warning:before {
  display: none;
}
.el-message-box {
  border-radius: 10px;
  width: 20vw !important;
  border: none;
  overflow: visible;
  border-radius: 15px;
}
.el-message-box__header {
  width: 100%;
  height: 2.5vw;
  background: #5990ff;
  border-radius: 10px 10px 0 0;
  border: 2px solid #5990ff;
  text-align: center;
  line-height: 2.5vw;
  .el-message-box__title {
    height: 100%;
    line-height: 0.5;
    font-size: 1.3vw;
    color: #fff;
  }
  .el-message-box__headerbtn {
    display: none;
  }
}
.el-message-box__content {
  height: 5vw;
  position: relative;
  padding: 0;
}
.el-message-box__container {
  width: 100%;
  text-align: center;
}
.el-message-box__message {
  height: 5vw;
  position: relative;
  p {
    width: 13vw;
    // height: 5vw;
    // line-height: 5vw;
    font-size: 1vw;
    position: absolute;
    padding-left: 1vw;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1.3;
  }
}
.el-message-box__status.el-icon-warning {
  background: url("../../assets/images/student/sigh.png") no-repeat;
  width: 2vw;
  height: 2vw;
  background-size: 100% 100%;
  position: absolute;
  left: 1.5vw;
}
.el-message-box__btns {
  text-align: center;
}
.el-button--small,
.el-button--small.is-round {
  padding: 1vh 2vw !important;
  font-size: 1.2vw;
  border-radius: 30px;
}
.el-message-box__status + .el-message-box__message {
  padding: 0 3vw;
}
</style>



